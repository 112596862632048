var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid general-component-wrap-background min-height-100vh"},[_c('div',{staticClass:"columns mt-0 pt-2"},[_c('div',{staticClass:"column p-0"},[_c('div',{staticClass:"is-flex"},[_c('p',{staticClass:"is-size-5 mx-4 mt-1 has-text-info-dark is-pulled-left"},[_c('span',{staticClass:"is-uppercase"},[_c('i',{staticClass:"fas fa-list mr-2"}),_vm._v(" "+_vm._s(_vm.$t("parameters"))+" ")])])])])]),_c('div',{staticClass:"columns mt-0"},[_c('parameters-general-info'),_c('div',{staticClass:"column p-0",class:[_vm.show_trucks_overview ? 'is-6' : 'is-9'],staticStyle:{"position":"relative"}},[_c('a',{staticClass:"is-pulled-right has-text-info-dark",class:{ flipped: !_vm.show_trucks_overview },staticStyle:{"position":"absolute","right":"0px"},attrs:{"href":"","title":"Trucks overview"},on:{"click":function($event){$event.preventDefault();_vm.show_trucks_overview = !_vm.show_trucks_overview}}},[_c('i',{staticClass:"fas fa-truck"})]),_c('table',{staticClass:"table table_col is-fullwidth delivery-parameters-table is-narrow background-transparent"},[_c('thead',{staticClass:"has-text-grey-light materials-header"},[_c('tr',[_c('th',{staticClass:"has-text-info-dark",attrs:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t("delivery_trucks"))+" ")]),_c('th',{staticClass:"has-text-info-dark"},[_vm._v(_vm._s(_vm.$t("returning_trucks")))]),_c('th',{staticClass:"has-text-info-dark py-0"})])]),_c('tbody',[_vm._l((Object.keys(_vm.warehouses)),function(key,index){return [_c('tr',{key:key + '-' + index,class:[
                _vm.warehouses[key].connected_project
                  ? 'is-cobalt-1 has-text-dark'
                  : '', // has connected projects
                _vm.warehouses[key].transit_project
                  ? // is not a warehouse which is not in our list of warehouses from where the project got stock (warehouses[key].transit_project == -1 )
                    // is a transit project which will bring materials to this project
                    _vm.warehouses[key].transit_project != -1
                    ? 'is-cobalt-1 has-text-light'
                    : // is a warehouse
                      'is-cobalt-1 has-text-light'
                  : '',
                _vm.warehouses[key].IdStock ? 'is-cobalt-6 has-text-light' : '' ]},[(_vm.warehouses[key].jobs[0].to_transit)?_c('th',{class:[
                  _vm.warehouses[key].jobs[0].to_transit
                    ? 'transparent-background'
                    : '' ],attrs:{"colspan":"1"}}):_vm._e(),_c('th',{staticClass:"is-size-7 pt-1 has-text-weight-bold",class:[
                  _vm.warehouses[key].jobs[0].to_transit
                    ? _vm.warehouses[key].jobs[0].connected_project != -1
                      ? 'is-cobalt-1 has-text-light'
                      : 'is-cobalt-6 has-text-light'
                    : '' ],attrs:{"colspan":_vm.warehouses[key].jobs[0].from_transit ? 1 : 4}},[(_vm.warehouses[key].connected_project)?_c('span',{staticClass:"is-flex is-flex-direction-column is-justify-content-center is-pulled-left mr-2"},[_c('img',{staticStyle:{"filter":"invert(100%)"},attrs:{"width":"16px","height":"16px","src":"/icons/transit_connection.png"}})]):_vm._e(),(_vm.warehouses[key].IdStock)?_c('span',{staticClass:"mr-2"},[_c('i',{staticClass:"fa fa-warehouse"})]):_vm._e(),(
                    !_vm.warehouses[key].transit_project &&
                    _vm.warehouses[key].Caption
                  )?_c('span',{staticClass:"mt-1 has-text-white"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.warehouses[key].Caption))+" ")]):_vm._e(),(
                    _vm.warehouses[key].transit_project && _vm.warehouses[key].Caption
                  )?_c('span',{staticClass:"is-pulled-right is-flex has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.warehouses[key].Caption))+" "),_c('img',{staticStyle:{"filter":"invert(100%)","margin-left":"4px"},attrs:{"width":"16px","height":"16px","src":"/icons/transit_connection.png"}})]):_vm._e()])]),_vm._l((_vm.warehouses_and_transits(key)),function(job,indexss){return [_c('tr',{key:key + '_' + indexss,staticClass:"has-text-grey"},[(job.details && job.details.departure_date)?_c('td',{staticClass:"has-text-weight-bold",staticStyle:{"color":"rgb(116, 159, 113)"}},[(!job.to_transit)?_c('span',{staticClass:"is-pulled-left"},[_vm._v(" "+_vm._s(_vm._f("customdate")(job.details.departure_date))+" ")]):_vm._e(),(!job.to_transit && !isNaN( parseFloat(
                          _vm.weights[
                            (job.Caption == '[TRANSIT-JOB]'
                              ? 'transit job '
                              : 'warehouse job ') + job.ID
                          ]
                        ) / 1000))?_c('span',{staticClass:"ml-4 has-text-dark"},[_c('progress-bar-loading',{attrs:{"total_capacity":_vm.total_truck_weight(
                          _vm.get_relative_parameters(job.ID, 2, 0)
                        ) / 1000,"total_used":parseFloat(
                          _vm.weights[
                            (job.Caption == '[TRANSIT-JOB]'
                              ? 'transit job '
                              : 'warehouse job ') + job.ID
                          ]
                        ) / 1000}}),_vm._v(" "+_vm._s(parseFloat( _vm.weights[ (job.Caption == "[TRANSIT-JOB]" ? "transit job " : "warehouse job ") + job.ID ] ) / 1000)+" "),_c('small',{staticClass:"has-text-grey-light"},[_vm._v("to")]),_vm._v(" / "+_vm._s(_vm.total_truck_weight( _vm.get_relative_parameters(job.ID, 2, 0) ) / 1000)),_c('small',{staticClass:"has-text-grey"},[_vm._v("to")])],1):_vm._e()]):_c('td',{staticClass:"has-text-weight-bold ml-2 has-text-grey",staticStyle:{"color":"#a6614d"}},[(job.details)?_c('span',[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"title":"This is also the delivery date of the event! \n                      Set this as the return date accordingly at the planning page. \n                      These dates should have been automatically set, unless these are old data, if not let the administrator know about this!"}}),_vm._v(" "+_vm._s(_vm._f("customdate")(job.details.DayTimeOut))+" ")]):_vm._e()]),(!job.from_transit)?_c('td',{staticClass:"has-text-weight-bold",staticStyle:{"color":"rgb(116, 159, 113)"}},[_vm._v(" "+_vm._s(_vm._f("customdate")(_vm.getReturningDates(job, indexss, key)))+" "),_c('span',{staticClass:"ml-4 has-text-dark"},[_c('progress-bar-loading',{attrs:{"total_capacity":_vm.total_truck_weight(
                          _vm.get_relative_parameters(job.ID, 2, 1)
                        ) / 1000,"total_used":parseFloat(
                          _vm.weights[
                            (job.Caption == '[TRANSIT-JOB]'
                              ? 'transit job '
                              : 'warehouse job ') + job.ID
                          ]
                        ) / 1000}}),_vm._v(" "+_vm._s(parseFloat(_vm.weights["warehouse job " + job.IdJob]) / 1000)),_c('small',{staticClass:"has-text-grey"},[_vm._v("to")]),_vm._v(" / "+_vm._s(_vm.total_truck_weight( _vm.get_relative_parameters(job.ID, 2, 1) ) / 1000)),_c('small',{staticClass:"has-text-grey"},[_vm._v("to")])],1)]):_vm._e(),_c('td',{staticClass:"t_d"}),_c('td',{staticClass:"t_d"})]),_c('tr',{key:key + 'adsad' + indexss},[(job.details)?_c('td',[(
                      _vm.am_allowed('parameter.can_create') &&
                      !job.connected_project &&
                      !job.transit_project
                    )?_c('div',{staticClass:"has-text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("create_trucks")))]),(_vm.$parent.truck_prefix)?_c('a',{staticClass:"ml-4 has-text-dark is-pulled-right",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.open_modal(
                          job.ID,
                          _vm.ej_trucks_list,
                          2,
                          'create',
                          [],
                          0,
                          job
                        )}}},[_c('i',{staticClass:"fas fa-plus-circle"})]):_vm._e()]):_vm._e(),(
                      _vm.am_allowed('parameter.can_create') &&
                      (job.connected_project || job.transit_project) &&
                      job.from_transit
                    )?_c('div',{staticClass:"has-text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("create_trucks")))]),(_vm.$parent.truck_prefix)?_c('a',{staticClass:"ml-4 has-text-dark is-pulled-right",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.open_modal(
                          job.ID,
                          _vm.ej_trucks_list,
                          2,
                          'create',
                          [],
                          0,
                          job
                        )}}},[_c('i',{staticClass:"fas fa-plus-circle"})]):_vm._e()]):_vm._e(),(job.details && job.Caption != '[TRANSIT-WAREHOUSE]')?_c('div',_vm._l((_vm.get_relative_parameters(
                        job.ID,
                        2,
                        0
                      )),function(truck,index){return _c('parameter-render',{key:index,attrs:{"relation":job.details.ID,"parameters_list":_vm.ej_trucks_list,"type":2,"form_mode":'edit',"param":truck,"index":index}})}),1):_vm._e(),_c('br')]):_vm._e(),_c('td',{attrs:{"colspan":"4"}},[(
                      _vm.am_allowed('parameter.can_create') &&
                      !job.connected_project &&
                      !job.transit_project
                    )?_c('div',{staticClass:"has-text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("create_trucks")))]),(_vm.$parent.truck_prefix)?_c('a',{staticClass:"ml-4 has-text-dark is-pulled-right",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.open_modal(
                          job.ID,
                          _vm.ej_trucks_list,
                          2,
                          'create',
                          [],
                          1,
                          job
                        )}}},[_c('i',{staticClass:"fas fa-plus-circle"})]):_vm._e()]):_vm._e(),(
                      _vm.am_allowed('parameter.can_create') &&
                      (job.connected_project || job.transit_project) &&
                      job.to_transit
                    )?_c('div',{staticClass:"has-text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$t("create_trucks")))]),(_vm.$parent.truck_prefix)?_c('a',{staticClass:"ml-4 has-text-dark is-pulled-right",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.open_modal(
                          job.ID,
                          _vm.ej_trucks_list,
                          2,
                          'create',
                          [],
                          1,
                          job
                        )}}},[_c('i',{staticClass:"fas fa-plus-circle"})]):_vm._e()]):_vm._e(),(
                      job.details &&
                      (job.to_transit ||
                        (!job.connected_project && !job.transit_project))
                    )?_c('div',_vm._l((_vm.get_relative_parameters(
                        job.ID,
                        2,
                        1
                      )),function(truck,index){return _c('parameter-render',{key:index,attrs:{"relation":job.details.ID,"parameters_list":_vm.ej_trucks_list,"type":2,"form_mode":'edit',"param":truck,"index":index}})}),1):_vm._e()])])]})]})],2)])]),(_vm.show_trucks_overview)?_c('div',{staticClass:"trucks-t-height custom_scrollbar-1 right-table column is-3 p-0"},[_c('parameters-truck-overview')],1):_vm._e()],1),_c('br'),_c('br'),(_vm.show_parameters_modal)?_c('parameters-modal',{attrs:{"current_form":_vm.current_form,"contact_persons":_vm.contact_persons_list,"parameters":{
      current_parameters_list: _vm.current_parameters_list,
      current_relation: _vm.current_relation,
      current_type: _vm.current_type,
      form_mode: _vm.form_mode,
      project: _vm.$parent.currentEvent,
      current_form: _vm.current_form,
      truck_prefix: _vm.$parent.truck_prefix,
    },"show_modal":'show_parameters_modal',"direction":_vm.current_direction},on:{"parameter_created":_vm.parameter_created,"close_modal":function($event){return _vm.close_modal('show_parameters_modal')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }