<template>
  <div
    class="container is-fluid general-component-wrap-background min-height-100vh"
  >
    <div class="columns mt-0 pt-2">
      <div class="column p-0">
        <div class="is-flex">
          <p class="is-size-5 mx-4 mt-1 has-text-info-dark is-pulled-left">
            <span class="is-uppercase">
              <i class="fas fa-list mr-2"></i> {{ $t("parameters") }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="columns mt-0">
      <parameters-general-info> </parameters-general-info>
      <!--Previous table-->
      <div
        class="column p-0"
        :class="[show_trucks_overview ? 'is-6' : 'is-9']"
        style="position: relative"
      >
        <a
          @click.prevent="show_trucks_overview = !show_trucks_overview"
          href=""
          :class="{ flipped: !show_trucks_overview }"
          title="Trucks overview"
          class="is-pulled-right has-text-info-dark"
          style="position: absolute; right: 0px"
        >
          <i class="fas fa-truck"></i>
        </a>
        <table
          class="table table_col is-fullwidth delivery-parameters-table is-narrow background-transparent"
        >
          <thead class="has-text-grey-light materials-header">
            <tr>
              <th class="has-text-info-dark" width="50%">
                {{ $t("delivery_trucks") }}
              </th>
              <th class="has-text-info-dark">{{ $t("returning_trucks") }}</th>
              <th class="has-text-info-dark py-0"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(key, index) in Object.keys(warehouses)">
              <tr
                :key="key + '-' + index"
                :class="[
                  warehouses[key].connected_project
                    ? 'is-cobalt-1 has-text-dark'
                    : '', // has connected projects
                  warehouses[key].transit_project
                    ? // is not a warehouse which is not in our list of warehouses from where the project got stock (warehouses[key].transit_project == -1 )
                      // is a transit project which will bring materials to this project
                      warehouses[key].transit_project != -1
                      ? 'is-cobalt-1 has-text-light'
                      : // is a warehouse
                        'is-cobalt-1 has-text-light'
                    : '',
                  warehouses[key].IdStock ? 'is-cobalt-6 has-text-light' : '', // has warehouse
                ]"
              >
                <th
                  colspan="1"
                  v-if="warehouses[key].jobs[0].to_transit"
                  :class="[
                    warehouses[key].jobs[0].to_transit
                      ? 'transparent-background'
                      : '',
                  ]"
                ></th>
                <th
                  :colspan="warehouses[key].jobs[0].from_transit ? 1 : 4"
                  class="is-size-7 pt-1 has-text-weight-bold"
                  :class="[
                    warehouses[key].jobs[0].to_transit
                      ? warehouses[key].jobs[0].connected_project != -1
                        ? 'is-cobalt-1 has-text-light'
                        : 'is-cobalt-6 has-text-light'
                      : '',
                  ]"
                >
                  <span
                    v-if="warehouses[key].connected_project"
                    class="is-flex is-flex-direction-column is-justify-content-center is-pulled-left mr-2"
                  >
                    <img
                      style="filter: invert(100%)"
                      width="16px"
                      height="16px"
                      src="/icons/transit_connection.png"
                    />
                  </span>
                  <span v-if="warehouses[key].IdStock" class="mr-2">
                    <i class="fa fa-warehouse"></i>
                  </span>
                  <!-- <pre>{{warehouses[key]}}</pre> -->

                  <span
                    class="mt-1 has-text-white"
                    v-if="
                      !warehouses[key].transit_project &&
                      warehouses[key].Caption
                    "
                  >
                    {{ warehouses[key].Caption | uppercase }}
                  </span>
                  <span
                    v-if="
                      warehouses[key].transit_project && warehouses[key].Caption
                    "
                    class="is-pulled-right is-flex has-text-weight-bold"
                  >
                    {{ warehouses[key].Caption | uppercase }}
                    <img
                      style="filter: invert(100%); margin-left: 4px"
                      width="16px"
                      height="16px"
                      src="/icons/transit_connection.png"
                    />
                  </span>

                  <!-- <small class="tag is-info is-pulled-right is-small">{{warehouses[key].jobs.length}}</small> -->
                </th>
              </tr>

              <template v-for="(job, indexss) in warehouses_and_transits(key)">
                <tr class="has-text-grey" :key="key + '_' + indexss">
                  <td
                    style="color: rgb(116, 159, 113)"
                    class="has-text-weight-bold"
                    v-if="job.details && job.details.departure_date"
                  >
                    <span class="is-pulled-left" v-if="!job.to_transit">
                      {{ job.details.departure_date | customdate }}
                    </span>
                    <span class="ml-4 has-text-dark" v-if="!job.to_transit && !isNaN( parseFloat(
                            weights[
                              (job.Caption == '[TRANSIT-JOB]'
                                ? 'transit job '
                                : 'warehouse job ') + job.ID
                            ]
                          ) / 1000)">
                      <progress-bar-loading
                        :total_capacity="
                          total_truck_weight(
                            get_relative_parameters(job.ID, 2, 0)
                          ) / 1000
                        "
                        :total_used="
                          parseFloat(
                            weights[
                              (job.Caption == '[TRANSIT-JOB]'
                                ? 'transit job '
                                : 'warehouse job ') + job.ID
                            ]
                          ) / 1000
                        "
                      >
                      </progress-bar-loading>
                      {{
                        parseFloat(
                          weights[
                            (job.Caption == "[TRANSIT-JOB]"
                              ? "transit job "
                              : "warehouse job ") + job.ID
                          ]
                        ) / 1000
                      }}
                      <small class="has-text-grey-light">to</small>
                      /
                      {{
                        total_truck_weight(
                          get_relative_parameters(job.ID, 2, 0)
                        ) / 1000
                      }}<small class="has-text-grey">to</small>
                    </span>
                  </td>
                  <td
                    style="color: #a6614d"
                    class="has-text-weight-bold ml-2 has-text-grey"
                    v-else
                  >
                    <span v-if="job.details">
                      <i
                        class="fa fa-exclamation-triangle"
                        title="This is also the delivery date of the event! 
                        Set this as the return date accordingly at the planning page. 
                        These dates should have been automatically set, unless these are old data, if not let the administrator know about this!"
                      ></i>
                      {{ job.details.DayTimeOut | customdate }}
                    </span>
                  </td>
                  <td
                    v-if="!job.from_transit"
                    style="color: rgb(116, 159, 113)"
                    class="has-text-weight-bold"
                  >
                    {{ getReturningDates(job, indexss, key) | customdate }}

                    <span class="ml-4 has-text-dark">
                      <!-- {{ job }} -->
                      <progress-bar-loading
                        :total_capacity="
                          total_truck_weight(
                            get_relative_parameters(job.ID, 2, 1)
                          ) / 1000
                        "
                        :total_used="
                          parseFloat(
                            weights[
                              (job.Caption == '[TRANSIT-JOB]'
                                ? 'transit job '
                                : 'warehouse job ') + job.ID
                            ]
                          ) / 1000
                        "
                      >
                      </progress-bar-loading>

                      {{
                        parseFloat(weights["warehouse job " + job.IdJob]) /
                        1000
                      }}<small class="has-text-grey">to</small>
                      /
                      {{
                        total_truck_weight(
                          get_relative_parameters(job.ID, 2, 1)
                        ) / 1000
                      }}<small class="has-text-grey">to</small>
                    </span>
                  </td>
                  <td class="t_d"></td>
                  <td class="t_d"></td>
                </tr>
                <tr :key="key + 'adsad' + indexss">
                  <!-- delivery trucks -->
                  <td v-if="job.details">
                    <div
                      v-if="
                        am_allowed('parameter.can_create') &&
                        !job.connected_project &&
                        !job.transit_project
                      "
                      class="has-text-right"
                    >
                      <strong>{{ $t("create_trucks") }}</strong>
                      <a
                        v-if="$parent.truck_prefix"
                        class="ml-4 has-text-dark is-pulled-right"
                        @click.prevent="
                          open_modal(
                            job.ID,
                            ej_trucks_list,
                            2,
                            'create',
                            [],
                            0,
                            job
                          )
                        "
                        href=""
                      >
                        <i class="fas fa-plus-circle"></i>
                      </a>
                    </div>

                    <div
                      v-if="
                        am_allowed('parameter.can_create') &&
                        (job.connected_project || job.transit_project) &&
                        job.from_transit
                      "
                      class="has-text-right"
                    >
                      <strong>{{ $t("create_trucks") }}</strong>
                      <a
                        v-if="$parent.truck_prefix"
                        class="ml-4 has-text-dark is-pulled-right"
                        @click.prevent="
                          open_modal(
                            job.ID,
                            ej_trucks_list,
                            2,
                            'create',
                            [],
                            0,
                            job
                          )
                        "
                        href=""
                      >
                        <i class="fas fa-plus-circle"></i>
                      </a>
                    </div>

                    <div
                      v-if="job.details && job.Caption != '[TRANSIT-WAREHOUSE]'"
                    >
                      <parameter-render
                        v-for="(truck, index) in get_relative_parameters(
                          job.ID,
                          2,
                          0
                        )"
                        :key="index"
                        :relation="job.details.ID"
                        :parameters_list="ej_trucks_list"
                        :type="2"
                        :form_mode="'edit'"
                        :param="truck"
                        :index="index"
                      ></parameter-render>
                    </div>
                    <br />
                  </td>

                  <!-- returning trucks list -->
                  <td colspan="4">
                    <div
                      v-if="
                        am_allowed('parameter.can_create') &&
                        !job.connected_project &&
                        !job.transit_project
                      "
                      class="has-text-right"
                    >
                      <strong>{{ $t("create_trucks") }}</strong>
                      <a
                        v-if="$parent.truck_prefix"
                        class="ml-4 has-text-dark is-pulled-right"
                        @click.prevent="
                          open_modal(
                            job.ID,
                            ej_trucks_list,
                            2,
                            'create',
                            [],
                            1,
                            job
                          )
                        "
                        href=""
                      >
                        <i class="fas fa-plus-circle"></i>
                      </a>
                    </div>
                    <div
                      v-if="
                        am_allowed('parameter.can_create') &&
                        (job.connected_project || job.transit_project) &&
                        job.to_transit
                      "
                      class="has-text-right"
                    >
                      <strong>{{ $t("create_trucks") }}</strong>
                      <a
                        v-if="$parent.truck_prefix"
                        class="ml-4 has-text-dark is-pulled-right"
                        @click.prevent="
                          open_modal(
                            job.ID,
                            ej_trucks_list,
                            2,
                            'create',
                            [],
                            1,
                            job
                          )
                        "
                        href=""
                      >
                        <i class="fas fa-plus-circle"></i>
                      </a>
                    </div>
                    <div
                      v-if="
                        job.details &&
                        (job.to_transit ||
                          (!job.connected_project && !job.transit_project))
                      "
                    >
                      <parameter-render
                        v-for="(truck, index) in get_relative_parameters(
                          job.ID,
                          2,
                          1
                        )"
                        :key="index"
                        :relation="job.details.ID"
                        :parameters_list="ej_trucks_list"
                        :type="2"
                        :form_mode="'edit'"
                        :param="truck"
                        :index="index"
                      ></parameter-render>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div
        v-if="show_trucks_overview"
        class="trucks-t-height custom_scrollbar-1 right-table column is-3 p-0"
      >
        <parameters-truck-overview></parameters-truck-overview>
      </div>
    </div>
    <br /><br />
    <!-- <pre>
      {{ ej_trucks_list }}
    </pre> -->

    <parameters-modal
      :current_form="current_form"
      :contact_persons="contact_persons_list"
      :parameters="{
        current_parameters_list,
        current_relation,
        current_type,
        form_mode,
        project: $parent.currentEvent,
        current_form,
        truck_prefix: $parent.truck_prefix,
      }"
      v-if="show_parameters_modal"
      :show_modal="'show_parameters_modal'"
      :direction="current_direction"
      @parameter_created="parameter_created"
      @close_modal="close_modal('show_parameters_modal')"
    >
    </parameters-modal>
  </div>
</template>

<script>
import socketMixin from "@/socket-mixin.js";
import socket from "@/socket.js";
import axios from "axios";
import permissionMixin from "@/permissions-mixin.js";
export default {
  mixins: [socketMixin, permissionMixin],
  data() {
    return {
      parameter_types: [],
      project_parameters: [],
      show_parameters_modal: false,
      ej_users_list: [],
      ej_trucks_list: [],
      current_parameters_list: [],
      current_relation: 0,
      current_type: 0,
      form_mode: "create",
      current_form: [],
      show_trucks_overview: false,
      current_direction: 0,
      transit_jobs: [],
      transit_warehouses: [],
      current_job: null,
    };
  },
  components: {
    "parameters-modal": () =>
      import("@/components/modals/parameters-modal.vue"),
    "parameter-render": () =>
      import("@/components/steps/Parameters/parameter-render.vue"),
    "parameters-general-info": () =>
      import("@/components/steps/Parameters/parameters_general_info.vue"),
    "parameters-truck-overview": () =>
      import("@/components/steps/Parameters/parameters_truck_overview.vue"),
    "progress-bar-loading": () =>
      import("@/components/steps/Parameters/progress-bar-loading.vue"),
  },
  computed: {
    weights() {
      var weights = {};
      this.$parent.jobs_warehouses.map((job) => {
        // map job.details.materials
        if (!job.details) return;

        if (!weights["warehouse job " + job.IdJob])
          weights["warehouse job " + job.IdJob] = 0;

          if(job.details.materials)
        job.details.materials.map((jmaterial) => {
          this.$parent.materials_data.map((material) => {
            if (material.IdStockType == jmaterial.IdST) {
              weights["warehouse job " + job.IdJob] +=
                material.Weight * jmaterial.Qty;
            }
          });
        });
      });
      this.$parent.jobs_requests.map((job) => {
        job.materials.map((jmaterial) => {
          this.$parent.materials_data.map((material) => {
            jmaterial.date_amount.map((date_amount) => {
              if (material.IdStockType == jmaterial.IdST) {
                if (!weights["request job " + job.IdJob])
                  weights["request job " + job.IdJob] = 0;
                weights["request job " + job.IdJob] +=
                  material.Weight * date_amount.Qty;

                if (date_amount.m_connected_project) {
                  if (
                    !weights["transit job " + date_amount.m_connected_project]
                  )
                    weights[
                      "transit job " + date_amount.m_connected_project
                    ] = 0;
                  weights["transit job " + date_amount.m_connected_project] +=
                    material.Weight * date_amount.Qty;
                }
              }
            });
          });
        });
        console.log(job, "request");
      });
      return weights;
    },
    connected_projects() {
      let con_projects = {};
      this.$parent.jobs.map((jw) => {
        if (jw.Caption.toLowerCase().includes("- request")) {
          jw.materials.map((m) => {
            m.date_amount.map((dm) => {
              if (dm.m_connected_project_id) {
                if (!con_projects["proj_" + dm.m_connected_project]) {
                  con_projects["proj_" + dm.m_connected_project] =
                    dm.m_connected_project_name;
                }
              }
            });
          });
        }
      });
      return con_projects;
    },
    warehouses() {
      var l = {};
      // Map all materials of the request job as if there are materials that the amounts get
      // picked from other projects, adds also these delivery jobs for each project.
      // These jobs cannot have returning jobs!
      // var dt1;
      var dt2;
      this.$parent.jobs.map((jw) => {
        if (jw.Caption.toLowerCase().includes("- request")) {
          // dt1 = jw.DayTimeOut
          dt2 = jw.DayTimeIn;

          jw.materials.map((m) => {
            m.date_amount.map((dm) => {
              if (dm.m_connected_project_id && !l[0 - dm.m_connected_project]) {
                if (this.connected_projects["proj_" + dm.m_connected_project])
                  this.connected_projects["proj_" + dm.m_connected_project] =
                    dm.m_connected_project_name;

                let d = jw.DayTimeOut.split("T");
                let d2 = jw.DayTimeIn.split("T");
                l[0 - dm.m_connected_project] = {
                  Id: dm.m_connected_project_id,
                  Caption: dm.m_connected_project_name,
                  connected_project: dm.m_connected_project,
                  jobs: [
                    {
                      Caption: "[TRANSIT-JOB]",
                      details: {
                        departure_date: d[0] + " " + d[1],
                        DayTimeIn: jw.DayTimeOut,
                        DayTimeOut: jw.DayTimeOut,
                        pickup_date: d2[0] + " " + d2[1],
                      },
                      ID: dm.m_connected_project,
                      connected_project: dm.m_connected_project,
                      connected_project_id: dm.m_connected_project_id,
                      from_transit: true,
                      to_transit: false,
                    },
                  ],
                };
              }
            });
          });
        }
      });

      // Map all warehouse jobs and let create trucks for them
      // at the return use the first return date for start of the returns to the warehouses
      this.$parent.jobs_warehouses.forEach((j) => {
        if (!l["w:" + j.Stock.ID]) {
          l["w:" + j.Stock.ID] = {};
          l["w:" + j.Stock.ID] = {
            ...j.Stock,
            jobs: [],
          };
        }
        l["w:" + j.Stock.ID].jobs.push(j);
      });

      // Map all the transit truck jobs that are connected to this project and list them at the return
      this.transit_jobs.map((tj) => {
        console.log("in transit dates", tj);
        l["tzj" + tj.ej_id] = {
          Id: tj.ej_id,
          Caption: tj.Caption,
          transit_project: tj.guid,
          jobs: [
            {
              Caption: "[TRANSIT-JOB]",
              details: {
                departure_date: tj.start_date,
                DayTimeIn: tj.start_date,
                DayTimeOut: tj.start_date,
                pickup_date: tj.start_date,
                ID: tj.id,
              },
              ID: tj.id,
              connected_project: tj.guid,
              connected_project_id: tj.ej_id,
              from_transit: false,
              to_transit: true,
            },
          ],
        };
      });

      this.transit_warehouses.map((tw) => {
        var wareh = this.$parent.warehouses.find(
          (a) => a.Id == tw.warehouse_id
        );
        l["twj" + tw.warehouse_id] = {
          Id: tw.warehouse_id,
          Caption: wareh ? wareh.Caption : "",
          transit_project: -1,
          jobs: [
            {
              Caption: "[TRANSIT-WAREHOUSE]",
              details: {
                departure_date: dt2,
                DayTimeIn: dt2,
                DayTimeOut: dt2,
                pickup_date: dt2,
              },
              ID: tw.warehouse_id,
              connected_project: -1, // -1 gets written in the database as null but gets evaluated in frontend as -1
              connected_project_id: -1,
              from_transit: false,
              to_transit: true,
              Stock: { ID: tw.warehouse_id },
            },
          ],
        };
      });

      return l;
    },
    users_list() {
      return this.project_parameters.filter((p) => p.type_id === 1);
    },
    trucks_list() {
      return this.project_parameters.filter((p) => p.type_id === 2);
    },
    locations_list() {
      return this.project_parameters.filter((p) => p.type_id === 3);
    },
    gate_users_list() {
      return this.project_parameters.filter((p) => p.type_id === 4);
    },
    contact_persons_list() {
      return this.project_parameters.filter((p) => p.type_id === 5);
    },
    // location_users_list(){},
    // truck_prefix(){
    //     return this.$parent.truck_prefix ?
    // }
  },
  mounted() {
    socket.on("someone said", this.socket_listener);
    if (this.$parent.event_jobs.length == 0)
      this.$parent.getWarehouseJobDetails();
  },
  beforeDestroy() {
    socket.off("someone said", this.socket_listener);
  },
  created() {
    if (this.am_allowed("parameter.can_read")) {
      // Check if have permissions can_see
      this.$document.title =
        "Parameters: " + this.$parent.event_details.Caption;
      if (this.$route.params.id && this.$parent.event_jobs.length == 0) {
        this.$parent.event_jobs = [];
        this.$parent.event_details.Jobs = [];
        this.$parent.currentEvent = this.$route.params.id;
        this.$parent.getProject();
      }
      axios
        .post(this.$tetris_server + "/get/project/parameters", {
          project_id: this.$parent.currentEvent,
        })
        .then((res) => {
          this.transit_warehouses = res.data[4];
          this.parameter_types = res.data[2].map((a) => {
            if (a.form_fields) a.form_fields = JSON.parse(a.form_fields);
            return a;
          });
          this.ej_users_list = res.data[1][0];
          this.ej_trucks_list = res.data[1][1];
          this.transit_jobs = res.data[3];
          if (this.$parent.event_jobs.length == 0)
            this.$parent.getWarehouseJobDetails();
          this.project_parameters = res.data[0].map((e) => {
            if (e.form_value) {
              // this should be dynamic... it should show the fields that is visible or the label
              if (e.table_id != "0") {
                if (e.type_id == 1 || e.type_id == 5) {
                  // userlist has the name of this table id
                  let user = this.ej_users_list.filter(
                    (u) => u.id == e.table_id
                  );
                  if (user.length == 1) {
                    e = { ...e, name: user[0], data: user[0] };
                    e.name = user[0].name;
                  }
                } else if (e.type_id == 2) {
                  // trucklist has the name of this table id
                  let truck = this.ej_trucks_list.filter(
                    (u) => u.id == e.table_id
                  );
                  if (truck.length == 1) {
                    e = { ...e, ...truck[0] };
                    e.name =
                      JSON.parse(e.form_value)[0].value + " - " + truck[0].name;
                    e.data = truck[0].name;
                  }
                }
                e.name =
                  e.name +
                  (e.name =
                    typeof e.form_value == "string" &&
                    JSON.parse(e.form_value).length > 0
                      ? this.getPreviewFields(
                          JSON.parse(e.form_value),
                          ", ",
                          e.type_id
                        )
                      : "");
              } else
                e.name =
                  typeof e.form_value == "string" &&
                  JSON.parse(e.form_value).length > 0
                    ? this.getPreviewFields(
                        JSON.parse(e.form_value),
                        "",
                        e.type_id
                      )
                    : this.$t("missing_value_general");
            }
            return e;
          });
        });

      this.$parent.app_lang = this.$i18n.locale;
      this.$parent.app_route = this.$parent.get_url();
    } else {
      if (this.am_allowed("request.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("warehouse.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("parameter.can_read")) {
        this.$router.push("/delivery-parameters/" + this.$route.params.id);
      } else if (this.am_allowed("planning.can_read")) {
        this.$router.push("/delivery-plan/" + this.$route.params.id);
      } else {
        this.$router.push("/");
      }
    }
  },
  methods: {
    warehouses_and_transits(key) {
      return this.warehouses[key].jobs.filter(
        (j) =>
          j.Caption.toLowerCase().includes("w:") ||
          j.Caption.toLowerCase().includes("[transit-job]") ||
          j.Caption.toLowerCase().includes("[transit-warehouse]")
      );
    },
    getReturningDates(job, indexss, key) {
      if (job.details) {
        var lst =
          indexss != 0
            ? this.warehouses[key].jobs.filter((j) =>
                j.Caption.toLowerCase().includes("w:")
              )[indexss - 1]
            : 0;
        return lst != 0 && lst.details
          ? lst.details.DayTimeIn != job.details.DayTimeIn
            ? job.details.DayTimeIn
            : ""
          : job.details.DayTimeIn;
      }
    },
    groupByKey(array, key) {
      let tas = array.reduce((hash, obj) => {
        if (obj[key]) var str = obj[key].split(" ")[0];
        if (str === undefined) return hash;
        return Object.assign(hash, { [str]: (hash[str] || []).concat(obj) });
      }, {});

      var s = {};
      Object.keys(tas)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .map((k) => {
          s[k] = tas[k];
        });

      // const sortable = Object.entries(tas)
      // .sort(([,a],[,b]) => new Date(b[1])-new Date(a[1]))
      // .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      return s;
    },
    parameter_created(d) {
      var created_param = { ...d };
      if (d.type_id == 2) {
        // this is a truck so automatically create also a truck job ready for planning
        var data;
        Object.keys(this.warehouses).map((w) => {
          // var pref = "";
          // if(job.Caption == '[TRANSIT-JOB]') pref = "-"
          // if(w == (prev == "-" ? 0 - this.current_job.ID : ))
          this.warehouses[w].jobs.map((wj) => {
            if (
              wj.ID == d.related_id &&
              this.current_job.Caption == wj.Caption
            ) {
              data = {
                project_id: this.$parent.currentEvent,
                truck_id: parseInt(d.id),
                ej_id: d.related_id,
                warehouse: wj,
                direction: this.current_direction,
                time: "08:00",
              };
            }
          });
          return w;
        });
        //console.log('all jobs', this.warehouses)
        //console.log('parameter was created', d, data)
        axios
          .post(this.$tetris_server + "/autocreate/truck", data)
          .then((response) => {
            if (response.data.length > 0) {
              var new_record = {};
              // this.project_parameters = [
              // ...this.project_parameters.map((pm) => {
              //  if (pm.id == created_param.id) {
              //  console.log(response.data[0], "aaa");

              new_record = {
                ...created_param,
                project_job_id: response.data[0].guid,
                status_id: response.data[0].status_id,
                direction: response.data[0].direction,
                arrives_at: response.data[0].details.arrives_at,
                connected_project_id: response.data[0].connected_project_id,
                contact_person: response.data[0].details.contact_person,
                origin: response.data[0].details.origin,
                origin_entity: response.data[0].details.origin_entity,
                destination: response.data[0].details.destination,
                destination_entity: response.data[0].details.destination_entity,
              };

              this.ej_trucks_list.map((t) => {
                if (parseFloat(t.id) == parseInt(created_param.table_id)) {
                  new_record.NetWeight = t.NetWeight;
                  new_record.NetVolume = t.NetVolume;
                }
              });
              // return null
              //}
              //else return pm
              // }),
              // ];
              //this.project_parameters.splice(-1)
              console.log(new_record, "push new");
              this.project_parameters.push(new_record);

              var d = {
                type: "new truck",
                data: {
                  ...new_record,
                  Qty: [],
                  job: {},
                  guid: "",
                },
              };
              this.$toast(this.$t("truck_created_planning"), "success", 600);
              setTimeout(() => {
                socket.emit("tell others", d);
              }, 1000);
            }
          });
      }
    },
    socket_listener(data) {
      let operator =
        '<p class = "has-text-primary-dark">' + " " + data.by.name + "</p>";
      console.log(data, "socket data");
      if (typeof data.data != "string") data.data.new_one = true;
      if (data.type == "truck prefix change") {
        this.$parent.truck_prefix = data.data;
        this.$toast(
          "<div>" + "Truck prefix was changed by " + operator + "</div>",
          "info"
        );
      } else if (data.type == "new truck") {
        this.project_parameters.push(data.data);
      } else if (data.type == "remove parameter") {
        if (data != 0) {
          this.project_parameters = [
            ...this.project_parameters.filter((p) => p.guid !== data.data),
          ];
          this.$toast(
            "<div>" +
              "One of the project parameter has been removed by" +
              operator +
              "</div",
            "info"
          );
        }
      } else if (data.type == "new parameter") {
        data.data.new_one = false;
        setTimeout(() => {
          data.data.new_one = true;
        }, 200);
        this.project_parameters.push(data.data);
        this.$toast(
          "<div>" +
            "One of the project parameter has been added by" +
            operator +
            "</div",
          "info"
        );
      } else if (data.type == "edit parameter") {
        this.project_parameters = this.project_parameters.map((p) => {
          if (p.guid == data.data.guid) {
            p = data.data;
            this.$toast(
              "<div>" +
                "One of the project parameter has been updated by " +
                operator +
                "</div>",
              "info"
            );
          }
          return p;
        });
      }
    },
    tell_others(data) {
      socket.emit("new parameter", data);
      socket.emit("tell others", { type: "new parameter", data: data });
    },
    tell_edits(data) {
      socket.emit("edit parameter", data);
      socket.emit("tell others", { type: "edit parameter", data: data });
    },
    getPreviewFields(fields, prefix, type) {
      let r = "";
      fields.map((f, idx) => {
        // if(type != 2 && idx != 0)
        if (f.preview == true) {
          if (type == 2 && idx != 0)
            r += (f.value ? f.value : this.$t("missing_value_general")) + ", ";
          else if (type != 2)
            r += (f.value ? f.value : this.$t("missing_value_general")) + ", ";
        }
        return f;
      });
      return r == ""
        ? /*fields[0].value*/ ""
        : prefix + r.substring(0, r.length - 2);
    },
    open_modal(
      relation,
      parameters_list,
      type,
      form_mode,
      current_form = [],
      current_direction,
      job
    ) {
      this.current_job = job;
      this.current_relation = relation;
      this.current_direction = current_direction;
      this.current_parameters_list = parameters_list;
      let t = this.parameter_types.filter((t) => t.id === type);
      this.current_type = t.length == 1 ? t[0] : null;
      this.form_mode = form_mode;
      this.current_form = current_form;
      if (this.form_mode == "edit") {
        if (typeof this.current_form.form_value == "string")
          this.current_form.form_value = JSON.parse(
            this.current_form.form_value
          );
      }
      this.show_parameters_modal = true;
    },
    total_truck_weight(data) {
      let total = 0;
      data.map((d) => {
        total += parseFloat(d.NetWeight);
      });
      return total;
    },
    get_relative_parameters(relation_id, type_id, direction = -1) {
      // console.log('rrr', r.data)

      let ret = [];
      if (direction != -1)
        ret = this.project_parameters.filter(
          (e) =>
            e.type_id == type_id &&
            e.related_id == relation_id &&
            e.direction == direction
        );
      else
        ret = this.project_parameters.filter(
          (e) => e.type_id == type_id && e.related_id == relation_id
        );
      return ret;
    },
    cancelcallback() {},
    close_modal(a) {
      this[a] = false;
    },
    get_users: async () => {
      return axios
        .post(this.$tetris_server + "/get/parameters/user", { r: 0 })
        .then((r) => {
          this.ej_users_list = r.data;
        });
    },
    get_trucks: async () => {
      return axios
        .post(this.$tetris_server + "/get/parameters/truck", { r: 0 })
        .then((r) => {
          // console.log('rrr', r.data)
          this.ej_trucks_list = r.data;
        });
    },
    remove_parameter(s) {
      this.$fire({
        text: this.$t("are_you_sure"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          s.form_value = "";
          var optionAxios = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          };
          axios
            .post(this.$tetris_server + "/remove/parameter", s, optionAxios)
            .then((r) => {
              if (r.data != 0) {
                socket.emit("tell others", {
                  type: "remove parameter",
                  data: r.data,
                });
                this.project_parameters = this.project_parameters.filter(
                  (p) => p.guid !== r.data
                );
                this.$toast("Parameter was removed", "danger");
              }
            });
        }
      });
    },
    save_truck_prefix(type) {
      axios
        .post(this.$tetris_server + "/save/truck/prefix", {
          project_id: this.$parent.currentEvent,
          prefix: this.$parent.truck_prefix,
          version: this.$parent.working_version,
        })
        .then((r) => {
          if (type == "prefix") {
            if (r.data == "prefix_empty_toaster") {
              this.$toast("Prefix data is empty", "danger");
            } else {
              this.$toast(this.$t("truck_name_saved"), "success");
              socket.emit("tell others", {
                type: "truck prefix change",
                data: this.$parent.truck_prefix,
              });
            }
          } else this.$toast("Project version saved", "success");
        });
    },
    get_param_name(id, list, token, short = false) {
      let a = list.filter((l) => l.id == id);

      if (a.length == 1) {
        //console.log(a[0].form_value )
        let fields =
          typeof a[0].form_value == "string"
            ? JSON.parse(a[0].form_value)
            : a[0].form_value;
        let truck_name = "";
        fields.map((f) => {
          if (f.value && f.preview && !short) truck_name += f.value + token;
          else if (f.value && f.preview && short && truck_name == "")
            truck_name = f.value;
        });
        truck_name = truck_name ? truck_name : "";

        if (short) {
          return a[0].data ? a[0].data.name : truck_name;
        }
        return truck_name + (a[0].data ? " " + a[0].data.name : "");
      } else return id;
    },
  },
};
</script>

<style scoped>
.table_col {
  border-spacing: 0em;
  margin: 0rem auto;
}

.table_col td {
  border: 1px solid #b9c2cb;
  border-width: 0 0 1px;
}

.table th {
  border: 0px solid #b9c2cb;
  border-width: 0 0 0px;
}

.delivery-parameters-table {
  border-bottom: solid 1px #dedede;
}

.table_border td {
  border-bottom: 1px solid #74b4c4 !important;
}

.right-table {
  margin-left: 15px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  background: #f6f7f9;
}

/* .confirmed-delivery-dates{
    
} */
.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  align-content: middle;
}

.row-reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-item {
  padding: 5px;
  margin: 5px;

  display: flex;
  align-items: center;
  justify-content: right;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.tag-name {
  white-space: pre-wrap;
  /* word-wrap: break-word; */

  text-align: left;
  max-width: 85% !important;
  height: 100%;
  padding: 3px 3px;
}

.tag-icon {
  align-self: stretch;
  height: inherit;
}

.general-info .column {
  padding: 2px;
}

.flipped {
  transform: scaleX(-1);
}

.trucks-t-height {
  height: calc(100vh - 70px);
  overflow-y: scroll;
}

.transparent-background {
  background: transparent !important;
}
</style>
